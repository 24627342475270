import React, {
  useState, useRef, useEffect as useLayoutEffect, useEffect,
} from 'react';
import { Link } from 'gatsby';
import * as styles from '../../scss/components/stickers/stickers.module.scss';
import StickerTopBar from './StickerTopBar';
import StickerCloseBar from './StickerCloseBar';

export default function StickerSpace(props: {
  topRange: number[],
  leftRange: number[],
  data: any,
}) {
  const {
    topRange,
    leftRange,
    data,
  } = props;

  const [hideSticker, setHideSticker] = useState(false);
  const stickerRef:any = useRef(null);

  function handleStickerClassHide() {
    if (!hideSticker) return styles.stickerSpace;
    return styles.stickerSpaceHide;
  }

  function handleSpaceTextClassName(index: number): any {
    switch (index % 3) {
      case 0:
        return styles.spaceText0;
      case 1:
        return styles.spaceText1;
      case 2:
        return styles.spaceText2;
      default:
        throw new Error(`Unexpected index value: ${index}`);
    }
  }

  function handleColors() {
    if (data.colorSelectMode === 'random') {
      const randomInt = Math.floor(Math.random() * (48 - 0) + 0);
      switch (randomInt) {
        case 0: return [styles.redOrange1, styles.redOrange2];
        case 1: return [styles.redYellow1, styles.redYellow2];
        case 2: return [styles.redPink1, styles.redPink2];
        case 3: return [styles.redBlack1, styles.redBlack2];
        case 4: return [styles.orangeRed1, styles.orangeRed2];
        case 5: return [styles.orangeBlue1, styles.orangeBlue2];
        case 6: return [styles.orangeYellow1, styles.orangeYellow2];
        case 7: return [styles.orangeHanul1, styles.orangeHanul2];
        case 8: return [styles.orangeBlack1, styles.orangeBlack2];
        case 9: return [styles.cyanRed1, styles.cyanRed2];
        case 10: return [styles.cyanBlue1, styles.cyanBlue2];
        case 11: return [styles.cyanHanul1, styles.cyanHanul2];
        case 12: return [styles.cyanBlack1, styles.cyanBlack2];
        case 13: return [styles.greenRed1, styles.greenRed2];
        case 14: return [styles.greenOrange1, styles.greenOrange2];
        case 15: return [styles.greenBlue1, styles.greenBlue2];
        case 16: return [styles.greenHanul1, styles.greenHanul2];
        case 17: return [styles.greenBlack1, styles.greenBlack2];
        case 18: return [styles.blueOrange1, styles.blueOrange2];
        case 19: return [styles.blueCyan1, styles.blueCyan2];
        case 20: return [styles.blueGreen1, styles.blueGreen2];
        case 21: return [styles.blueYellow1, styles.blueYellow2];
        case 22: return [styles.bluePink1, styles.bluePink2];
        case 23: return [styles.blueBlack1, styles.blueBlack2];
        case 24: return [styles.yellowRed1, styles.yellowRed2];
        case 25: return [styles.yellowBlue1, styles.yellowBlue2];
        case 26: return [styles.yellowPink1, styles.yellowPink2];
        case 27: return [styles.yellowHanul1, styles.yellowHanul2];
        case 28: return [styles.yellowBlack1, styles.yellowBlack2];
        case 29: return [styles.pinkRed1, styles.pinkRed2];
        case 30: return [styles.pinkBlue1, styles.pinkBlue2];
        case 31: return [styles.pinkYellow1, styles.pinkYellow2];
        case 32: return [styles.pinkHanul1, styles.pinkHanul2];
        case 33: return [styles.pinkBlack1, styles.pinkBlack2];
        case 34: return [styles.hanulOrange1, styles.hanulOrange2];
        case 35: return [styles.hanulCyan1, styles.hanulCyan2];
        case 36: return [styles.hanulGreen1, styles.hanulGreen2];
        case 37: return [styles.hanulYellow1, styles.hanulYellow2];
        case 38: return [styles.hanulPink1, styles.hanulPink2];
        case 39: return [styles.hanulBlack1, styles.hanulBlack2];
        case 40: return [styles.blackRed1, styles.blackRed2];
        case 41: return [styles.blackOrange1, styles.blackOrange2];
        case 42: return [styles.blackCyan1, styles.blackCyan2];
        case 43: return [styles.blackGreen1, styles.blackGreen2];
        case 44: return [styles.blackBlue1, styles.blackBlue2];
        case 45: return [styles.blackYellow1, styles.blackYellow2];
        case 46: return [styles.blackPink1, styles.blackPink2];
        case 47: return [styles.blackHanul1, styles.blackHanul2];
        default:
          throw new Error(`Unexpected value of randomInt ${randomInt}`);
      }
    } else if (data.colorSelectMode === 'manual') {
      if (data.darkColor === 'red' && data.lightColor === 'orange') return [styles.redOrange1, styles.redOrange2];
      if (data.darkColor === 'red' && data.lightColor === 'yellow') return [styles.redYellow1, styles.redYellow2];
      if (data.darkColor === 'red' && data.lightColor === 'pink') return [styles.redPink1, styles.redPink2];
      if (data.darkColor === 'red' && data.lightColor === 'black') return [styles.redBlack1, styles.redBlack2];
      if (data.darkColor === 'orange' && data.lightColor === 'red') return [styles.orangeRed1, styles.orangeRed2];
      if (data.darkColor === 'orange' && data.lightColor === 'blue') return [styles.orangeBlue1, styles.orangeBlue2];
      if (data.darkColor === 'orange' && data.lightColor === 'yellow') return [styles.orangeYellow1, styles.orangeYellow2];
      if (data.darkColor === 'orange' && data.lightColor === 'hanul') return [styles.orangeHanul1, styles.orangeHanul2];
      if (data.darkColor === 'orange' && data.lightColor === 'black') return [styles.orangeBlack1, styles.orangeBlack2];
      if (data.darkColor === 'cyan' && data.lightColor === 'red') return [styles.cyanRed1, styles.cyanRed2];
      if (data.darkColor === 'cyan' && data.lightColor === 'blue') return [styles.cyanBlue1, styles.cyanBlue2];
      if (data.darkColor === 'cyan' && data.lightColor === 'hanul') return [styles.cyanHanul1, styles.cyanHanul2];
      if (data.darkColor === 'cyan' && data.lightColor === 'black') return [styles.cyanBlack1, styles.cyanBlack2];
      if (data.darkColor === 'green' && data.lightColor === 'red') return [styles.greenRed1, styles.greenRed2];
      if (data.darkColor === 'green' && data.lightColor === 'orange') return [styles.greenOrange1, styles.greenOrange2];
      if (data.darkColor === 'green' && data.lightColor === 'blue') return [styles.greenBlue1, styles.greenBlue2];
      if (data.darkColor === 'green' && data.lightColor === 'hanul') return [styles.greenHanul1, styles.greenHanul2];
      if (data.darkColor === 'green' && data.lightColor === 'black') return [styles.greenBlack1, styles.greenBlack2];
      if (data.darkColor === 'blue' && data.lightColor === 'orange') return [styles.blueOrange1, styles.blueOrange2];
      if (data.darkColor === 'blue' && data.lightColor === 'cyan') return [styles.blueCyan1, styles.blueCyan2];
      if (data.darkColor === 'blue' && data.lightColor === 'green') return [styles.blueGreen1, styles.blueGreen2];
      if (data.darkColor === 'blue' && data.lightColor === 'yellow') return [styles.blueYellow1, styles.blueYellow2];
      if (data.darkColor === 'blue' && data.lightColor === 'pink') return [styles.bluePink1, styles.bluePink2];
      if (data.darkColor === 'blue' && data.lightColor === 'black') return [styles.blueBlack1, styles.blueBlack2];
      if (data.darkColor === 'yellow' && data.lightColor === 'red') return [styles.yellowRed1, styles.yellowRed2];
      if (data.darkColor === 'yellow' && data.lightColor === 'blue') return [styles.yellowBlue1, styles.yellowBlue2];
      if (data.darkColor === 'yellow' && data.lightColor === 'pink') return [styles.yellowPink1, styles.yellowPink2];
      if (data.darkColor === 'yellow' && data.lightColor === 'hanul') return [styles.yellowHanul1, styles.yellowHanul2];
      if (data.darkColor === 'yellow' && data.lightColor === 'black') return [styles.yellowBlack1, styles.yellowBlack2];
      if (data.darkColor === 'pink' && data.lightColor === 'red') return [styles.pinkRed1, styles.pinkRed2];
      if (data.darkColor === 'pink' && data.lightColor === 'blue') return [styles.pinkBlue1, styles.pinkBlue2];
      if (data.darkColor === 'pink' && data.lightColor === 'yellow') return [styles.pinkYellow1, styles.pinkYellow2];
      if (data.darkColor === 'pink' && data.lightColor === 'hanul') return [styles.pinkHanul1, styles.pinkHanul2];
      if (data.darkColor === 'pink' && data.lightColor === 'black') return [styles.pinkBlack1, styles.pinkBlack2];
      if (data.darkColor === 'hanul' && data.lightColor === 'orange') return [styles.hanulOrange1, styles.hanulOrange2];
      if (data.darkColor === 'hanul' && data.lightColor === 'cyan') return [styles.hanulCyan1, styles.hanulCyan2];
      if (data.darkColor === 'hanul' && data.lightColor === 'green') return [styles.hanulGreen1, styles.hanulGreen2];
      if (data.darkColor === 'hanul' && data.lightColor === 'yellow') return [styles.hanulYellow1, styles.hanulYellow2];
      if (data.darkColor === 'hanul' && data.lightColor === 'pink') return [styles.hanulPink1, styles.hanulPink2];
      if (data.darkColor === 'hanul' && data.lightColor === 'black') return [styles.hanulBlack1, styles.hanulBlack2];
      if (data.darkColor === 'black' && data.lightColor === 'red') return [styles.blackRed1, styles.blackRed2];
      if (data.darkColor === 'black' && data.lightColor === 'orange') return [styles.blackOrange1, styles.blackOrange2];
      if (data.darkColor === 'black' && data.lightColor === 'cyan') return [styles.blackCyan1, styles.blackCyan2];
      if (data.darkColor === 'black' && data.lightColor === 'green') return [styles.blackGreen1, styles.blackGreen2];
      if (data.darkColor === 'black' && data.lightColor === 'blue') return [styles.blackBlue1, styles.blackBlue2];
      if (data.darkColor === 'black' && data.lightColor === 'yellow') return [styles.blackYellow1, styles.blackYellow2];
      if (data.darkColor === 'black' && data.lightColor === 'pink') return [styles.blackPink1, styles.blackPink2];
      if (data.darkColor === 'black' && data.lightColor === 'hanul') return [styles.blackHanul1, styles.blackHanul2];
    }
    return true;
  }
  const thisStickersColors: any = handleColors();

  function getRandomRotation() {
    const randomDegree = Math.floor(Math.random() * (65 - 0) + 0);
    const randomSign = Math.round(Math.random());
    return randomSign ? randomDegree : randomDegree * -1;
  }
  const randomRotation = getRandomRotation();

  function getTextObject(spaceText: any): any {
    return spaceText.map(
      (block: any, blockIndex: number) => block.children.map(
        (child: any) => (child.text.split('\n').map(
          (line: any, i: number) => ({
            key: `${blockIndex}-${i}`,
            className: handleSpaceTextClassName(blockIndex),
            text: line,
          }),
        )),
      ),
    );
  }

  const design1 = (
    <div className={`${styles.design1container} ${thisStickersColors[0]}`}>
      <div className={`${styles.design1content} ${thisStickersColors[1]}`} style={{ border: 'none' }}>
        <Link className={styles.link} to="/space">
          <StickerTopBar
            location="space"
            design="1"
            colorStyle={thisStickersColors[1]}
            topLineText={data.topLineSpace}
          />
          <div className={`${styles.midBar} ${thisStickersColors[1]} ${styles.spaceStickerTextBlock}`}>
            {getTextObject(data.textSpace).flat(2).map((text: any) => (
              <p key={text.key} className={text.className}>{text.text}</p>
            ))}
          </div>
        </Link>
      </div>
      <StickerCloseBar
        location="space"
        closeButtonText={data.closeButtonText}
        colorStyle={thisStickersColors[0]}
        hideSticker={hideSticker}
        setHideSticker={setHideSticker}
      />
    </div>
  );
  const design2 = (
    <div className={`${styles.design5container} ${thisStickersColors[0]}`}>
      <div className={`${styles.design5content} ${thisStickersColors[0]}`} style={{ border: 'none' }}>
        <Link className={styles.link} to="/space">
          <StickerTopBar
            location="space"
            design="4"
            colorStyle={thisStickersColors[1]}
            topLineText={data.topLineSpace}
          />
          <div className={`${styles.midBar} ${thisStickersColors[0]}`}>
            {getTextObject(data.textSpace).flat(2).map((text: any) => (
              <p key={text.key} className={text.className}>{text.text}</p>
            ))}
          </div>
          <div className={`${thisStickersColors[1]}`} style={{ width: '100%', height: '1px' }} />
        </Link>
        <StickerCloseBar
          location="space"
          closeButtonText={data.closeButtonText}
          colorStyle={thisStickersColors[0]}
          hideSticker={hideSticker}
          setHideSticker={setHideSticker}
        />
      </div>
    </div>
  );
  const design3 = (
    <div className={`${styles.design5container} ${thisStickersColors[0]}`}>
      <div className={`${styles.design5content} ${thisStickersColors[0]}`}>
        <Link className={styles.link} to="/space">
          <StickerTopBar
            location="space"
            design="4"
            colorStyle={thisStickersColors[0]}
            topLineText={data.topLineSpace}
          />
          <div className={`${thisStickersColors[1]}`} style={{ width: '100%', height: '1px' }} />
          <div className={`${styles.midBar} ${thisStickersColors[0]}`}>
            {getTextObject(data.textSpace).flat(2).map((text: any) => (
              <p key={text.key} className={text.className}>{text.text}</p>
            ))}
          </div>
          <div className={`${thisStickersColors[1]}`} style={{ width: '100%', height: '1px' }} />
        </Link>
        <StickerCloseBar
          location="space"
          closeButtonText={data.closeButtonText}
          colorStyle={thisStickersColors[0]}
          hideSticker={hideSticker}
          setHideSticker={setHideSticker}
        />
      </div>
    </div>
  );
  const design4 = (
    <div className={`${styles.design5container} ${thisStickersColors[0]}`}>
      <Link className={styles.link} to="/space">
        <StickerTopBar
          location="space"
          design="4"
          colorStyle={thisStickersColors[0]}
          topLineText={data.topLineSpace}
        />
        <div className={`${thisStickersColors[1]}`} style={{ width: '100%', height: '1px' }} />
        <div className={`${styles.midBar} ${thisStickersColors[0]}`}>
          {getTextObject(data.textSpace).flat(2).map((text: any) => (
            <p key={text.key} className={text.className}>{text.text}</p>
          ))}
        </div>
        <div className={`${thisStickersColors[1]}`} style={{ width: '100%', height: '1px' }} />
      </Link>
      <StickerCloseBar
        location="space"
        closeButtonText={data.closeButtonText}
        colorStyle={thisStickersColors[0]}
        hideSticker={hideSticker}
        setHideSticker={setHideSticker}
      />
    </div>
  );
  const design5 = (
    <div className={`${styles.design5container} ${thisStickersColors[0]}`}>
      <div className={`${styles.design5content} ${thisStickersColors[0]}`}>
        <Link className={styles.link} to="/space">
          <div className={`${styles.midBar} ${thisStickersColors[0]}`}>
            {getTextObject(data.textSpace).flat(2).map((text: any) => (
              <p key={text.key} className={text.className}>{text.text}</p>
            ))}
          </div>
        </Link>
        <div className={`${thisStickersColors[1]}`} style={{ width: '100%', height: '1px' }} />
        <StickerCloseBar
          location="space"
          closeButtonText={data.closeButtonText}
          colorStyle={thisStickersColors[0]}
          hideSticker={hideSticker}
          setHideSticker={setHideSticker}
        />
      </div>
    </div>
  );

  const randomNumber = Math.floor(Math.random() * (6 - 1) + 1);
  function pickStickerDesign() {
    switch (randomNumber) {
      case 1:
        return design1;
      case 2:
        return design2;
      case 3:
        return design3;
      case 4:
        return design4;
      case 5:
        return design5;
      default:
        throw new Error('Unexpected random number.');
    }
  }

  function getRandomPct(min: number, max: number): string {
    const randomInt = Math.floor(Math.random() * ((max + 1) - min) + min);
    return `${randomInt}%`;
  }

  useLayoutEffect(() => {
    stickerRef.current.style.transition = 'top 0.5s ease-out, left 0.5s ease-out';
    stickerRef.current.style.top = getRandomPct(topRange[0], topRange[1]);
    stickerRef.current.style.left = getRandomPct(leftRange[0], leftRange[1]);
    stickerRef.current.style.transform = `rotate(${randomRotation}deg)`;
    stickerRef.current.style.display = 'flex';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (hideSticker) {
      stickerRef.current.style.display = 'none';
    } else {
      stickerRef.current.style.display = 'flex';
    }
  }, [hideSticker]);

  return (
    <div className={handleStickerClassHide()} ref={stickerRef}>
      {pickStickerDesign()}
    </div>
  );
}
