// extracted by mini-css-extract-plugin
export var bgBlack = "stickers-module--bg-black--4eaf5";
export var bgBlue = "stickers-module--bg-blue--ce9b0";
export var bgCyan = "stickers-module--bg-cyan--c3099";
export var bgGreen = "stickers-module--bg-green--744ac";
export var bgHanul = "stickers-module--bg-hanul--d0b7b";
export var bgOrange = "stickers-module--bg-orange--e24f5";
export var bgPink = "stickers-module--bg-pink--61687";
export var bgRed = "stickers-module--bg-red--03b96";
export var bgYellow = "stickers-module--bg-yellow--528f9";
export var blackBlue1 = "stickers-module--black-blue-1--39ceb";
export var blackBlue2 = "stickers-module--black-blue-2--7c63b";
export var blackCyan1 = "stickers-module--black-cyan-1--8c0b9";
export var blackCyan2 = "stickers-module--black-cyan-2--33bb4";
export var blackGreen1 = "stickers-module--black-green-1--2f0f1";
export var blackGreen2 = "stickers-module--black-green-2--665e6";
export var blackHanul1 = "stickers-module--black-hanul-1--e60f6";
export var blackHanul2 = "stickers-module--black-hanul-2--814e9";
export var blackOrange1 = "stickers-module--black-orange-1--98aff";
export var blackOrange2 = "stickers-module--black-orange-2--bb59c";
export var blackPink1 = "stickers-module--black-pink-1--4d113";
export var blackPink2 = "stickers-module--black-pink-2--100c7";
export var blackRed1 = "stickers-module--black-red-1--db438";
export var blackRed2 = "stickers-module--black-red-2--ee4f5";
export var blackYellow1 = "stickers-module--black-yellow-1--064c6";
export var blackYellow2 = "stickers-module--black-yellow-2--a89d8";
export var blueBlack1 = "stickers-module--blue-black-1--8721f";
export var blueBlack2 = "stickers-module--blue-black-2--8f3c7";
export var blueCyan1 = "stickers-module--blue-cyan-1--6bd4a";
export var blueCyan2 = "stickers-module--blue-cyan-2--24242";
export var blueGreen1 = "stickers-module--blue-green-1--181d2";
export var blueGreen2 = "stickers-module--blue-green-2--513cf";
export var blueOrange1 = "stickers-module--blue-orange-1--8ef0c";
export var blueOrange2 = "stickers-module--blue-orange-2--d477b";
export var bluePink1 = "stickers-module--blue-pink-1--cfddc";
export var bluePink2 = "stickers-module--blue-pink-2--21f95";
export var blueYellow1 = "stickers-module--blue-yellow-1--74db6";
export var blueYellow2 = "stickers-module--blue-yellow-2--56b84";
export var borderBlack = "stickers-module--border-black--ca57b";
export var borderBlue = "stickers-module--border-blue--e2c29";
export var borderCyan = "stickers-module--border-cyan--1ef5d";
export var borderGreen = "stickers-module--border-green--ef447";
export var borderHanul = "stickers-module--border-hanul--3156c";
export var borderOrange = "stickers-module--border-orange--011ad";
export var borderPink = "stickers-module--border-pink--e2062";
export var borderRed = "stickers-module--border-red--53ae2";
export var borderYellow = "stickers-module--border-yellow--c5a5f";
export var closeBar = "stickers-module--closeBar--ab0cb";
export var closeBarP = "stickers-module--closeBarP--b0372";
export var closeBarPSpace = "stickers-module--closeBarPSpace--28ea3";
export var cyanBlack1 = "stickers-module--cyan-black-1--4fbc0";
export var cyanBlack2 = "stickers-module--cyan-black-2--73ea7";
export var cyanBlue1 = "stickers-module--cyan-blue-1--b94fb";
export var cyanBlue2 = "stickers-module--cyan-blue-2--a09a2";
export var cyanHanul1 = "stickers-module--cyan-hanul-1--2326e";
export var cyanHanul2 = "stickers-module--cyan-hanul-2--5a666";
export var cyanOrange1 = "stickers-module--cyan-orange-1--5ad60";
export var cyanOrange2 = "stickers-module--cyan-orange-2--7420d";
export var cyanRed1 = "stickers-module--cyan-red-1--d57b0";
export var cyanRed2 = "stickers-module--cyan-red-2--6990d";
export var design1container = "stickers-module--design1container--d971a";
export var design1content = "stickers-module--design1content--7198b";
export var design5container = "stickers-module--design5container--1e54c";
export var design5content = "stickers-module--design5content--b8c8b";
export var greenBlack1 = "stickers-module--green-black-1--2c320";
export var greenBlack2 = "stickers-module--green-black-2--f899b";
export var greenBlue1 = "stickers-module--green-blue-1--90d45";
export var greenBlue2 = "stickers-module--green-blue-2--2b778";
export var greenHanul1 = "stickers-module--green-hanul-1--6938e";
export var greenHanul2 = "stickers-module--green-hanul-2--cd39e";
export var greenOrange1 = "stickers-module--green-orange-1--207fb";
export var greenOrange2 = "stickers-module--green-orange-2--f85c4";
export var greenRed1 = "stickers-module--green-red-1--086c6";
export var greenRed2 = "stickers-module--green-red-2--a43c5";
export var hanulBlack1 = "stickers-module--hanul-black-1--f7e1e";
export var hanulBlack2 = "stickers-module--hanul-black-2--6210c";
export var hanulCyan1 = "stickers-module--hanul-cyan-1--a7b39";
export var hanulCyan2 = "stickers-module--hanul-cyan-2--b5c9b";
export var hanulGreen1 = "stickers-module--hanul-green-1--34984";
export var hanulGreen2 = "stickers-module--hanul-green-2--3cdf1";
export var hanulOrange1 = "stickers-module--hanul-orange-1--a90e6";
export var hanulOrange2 = "stickers-module--hanul-orange-2--90ca4";
export var hanulPink1 = "stickers-module--hanul-pink-1--2f6ea";
export var hanulPink2 = "stickers-module--hanul-pink-2--b0edd";
export var hanulYellow1 = "stickers-module--hanul-yellow-1--d3264";
export var hanulYellow2 = "stickers-module--hanul-yellow-2--dc02f";
export var link = "stickers-module--link--35d24";
export var midBar = "stickers-module--midBar--7119f";
export var midBarAlignLeft = "stickers-module--midBarAlignLeft--31542";
export var midBarAlignRight = "stickers-module--midBarAlignRight--d4c64";
export var orangeBlack1 = "stickers-module--orange-black-1--9b726";
export var orangeBlack2 = "stickers-module--orange-black-2--a441a";
export var orangeBlue1 = "stickers-module--orange-blue-1--d9ac3";
export var orangeBlue2 = "stickers-module--orange-blue-2--1b47e";
export var orangeCyan1 = "stickers-module--orange-cyan-1--1ffff";
export var orangeCyan2 = "stickers-module--orange-cyan-2--9e339";
export var orangeGreen1 = "stickers-module--orange-green-1--3a4ec";
export var orangeGreen2 = "stickers-module--orange-green-2--f5b0e";
export var orangeHanul1 = "stickers-module--orange-hanul-1--207b0";
export var orangeHanul2 = "stickers-module--orange-hanul-2--bd281";
export var orangeRed1 = "stickers-module--orange-red-1--63f8a";
export var orangeRed2 = "stickers-module--orange-red-2--d8e48";
export var orangeYellow1 = "stickers-module--orange-yellow-1--3e2b0";
export var orangeYellow2 = "stickers-module--orange-yellow-2--6ddf4";
export var pinkBlack1 = "stickers-module--pink-black-1--f75d5";
export var pinkBlack2 = "stickers-module--pink-black-2--b261d";
export var pinkBlue1 = "stickers-module--pink-blue-1--257e0";
export var pinkBlue2 = "stickers-module--pink-blue-2--e2462";
export var pinkHanul1 = "stickers-module--pink-hanul-1--9cb97";
export var pinkHanul2 = "stickers-module--pink-hanul-2--10704";
export var pinkRed1 = "stickers-module--pink-red-1--88078";
export var pinkRed2 = "stickers-module--pink-red-2--2e341";
export var pinkYellow1 = "stickers-module--pink-yellow-1--9f6ec";
export var pinkYellow2 = "stickers-module--pink-yellow-2--a4ec4";
export var redBlack1 = "stickers-module--red-black-1--fd3f2";
export var redBlack2 = "stickers-module--red-black-2--7cc0c";
export var redCyan1 = "stickers-module--red-cyan-1--bab35";
export var redCyan2 = "stickers-module--red-cyan-2--2b16a";
export var redGreen1 = "stickers-module--red-green-1--7196d";
export var redGreen2 = "stickers-module--red-green-2--3183e";
export var redOrange1 = "stickers-module--red-orange-1--0ae73";
export var redOrange2 = "stickers-module--red-orange-2--e087a";
export var redPink1 = "stickers-module--red-pink-1--c0855";
export var redPink2 = "stickers-module--red-pink-2--40cb0";
export var redYellow1 = "stickers-module--red-yellow-1--274e2";
export var redYellow2 = "stickers-module--red-yellow-2--eccaf";
export var spaceStickerTextBlock = "stickers-module--spaceStickerTextBlock--fb72b";
export var spaceText0 = "stickers-module--spaceText0--32f54";
export var spaceText1 = "stickers-module--spaceText1--abdc7";
export var spaceText2 = "stickers-module--spaceText2--2389a";
export var sticker = "stickers-module--sticker--5cc11";
export var sticker1 = "stickers-module--sticker1--c26d3";
export var sticker1Hide = "stickers-module--sticker1Hide--a3c62";
export var sticker2 = "stickers-module--sticker2--391bb";
export var sticker2Hide = "stickers-module--sticker2Hide--80858";
export var sticker3 = "stickers-module--sticker3--c8539";
export var sticker3Hide = "stickers-module--sticker3Hide--c6406";
export var stickerComponent = "stickers-module--stickerComponent--77a6e";
export var stickerDateTime = "stickers-module--stickerDateTime--c4c4d";
export var stickerPeople = "stickers-module--stickerPeople--9626c";
export var stickerSpace = "stickers-module--stickerSpace--5ff6c";
export var stickerSpaceHide = "stickers-module--stickerSpaceHide--7769a";
export var stickerTitle = "stickers-module--stickerTitle--2ff72";
export var textBlack = "stickers-module--text-black--36adf";
export var textBlue = "stickers-module--text-blue--f0bf6";
export var textCyan = "stickers-module--text-cyan--03b40";
export var textGreen = "stickers-module--text-green--4053a";
export var textHanul = "stickers-module--text-hanul--57e48";
export var textOrange = "stickers-module--text-orange--80cbd";
export var textPink = "stickers-module--text-pink--5048b";
export var textRed = "stickers-module--text-red--496ed";
export var textYellow = "stickers-module--text-yellow--87080";
export var topBar = "stickers-module--topBar--45ea2";
export var topBarDesign2 = "stickers-module--topBarDesign2--adea8";
export var topBarP = "stickers-module--topBarP--fa51f";
export var topBarPDesign1 = "stickers-module--topBarPDesign1--2c45f";
export var topBarPSpace = "stickers-module--topBarPSpace--17364";
export var topBarStarSpace = "stickers-module--topBarStarSpace--0607d";
export var yellowBlack1 = "stickers-module--yellow-black-1--d17b6";
export var yellowBlack2 = "stickers-module--yellow-black-2--6cdd9";
export var yellowBlue1 = "stickers-module--yellow-blue-1--98844";
export var yellowBlue2 = "stickers-module--yellow-blue-2--ffbea";
export var yellowHanul1 = "stickers-module--yellow-hanul-1--24036";
export var yellowHanul2 = "stickers-module--yellow-hanul-2--3cf0e";
export var yellowPink1 = "stickers-module--yellow-pink-1--cdf3b";
export var yellowPink2 = "stickers-module--yellow-pink-2--d40fc";
export var yellowRed1 = "stickers-module--yellow-red-1--f07e1";
export var yellowRed2 = "stickers-module--yellow-red-2--5db60";