import React, {
  useState, useRef, useLayoutEffect, useEffect,
} from 'react';
import { Link } from 'gatsby';
import * as styles from '../../scss/components/stickers/stickers.module.scss';
import StickerCloseBar from './StickerCloseBar';
import StickerTopBar from './StickerTopBar';

export default function StickerGeneral(props: {
  topRange: number[],
  leftRange: number[],
  data: any,
}) {
  const {
    topRange,
    leftRange,
    data,
  } = props;

  const [hideSticker, setHideSticker] = useState(false);
  const stickerRef:any = useRef(null);

  function getStickerClass() {
    if (!hideSticker) {
      switch (data.titleInternal.charAt(data.titleInternal.length - 1)) {
        case '1':
          return styles.sticker1;
        case '2':
          return styles.sticker2;
        case '3':
          return styles.sticker3;
        default:
          throw new Error('Unexpected value of stickerNum');
      }
    } else {
      switch (data.titleInternal.charAt(data.titleInternal.length - 1)) {
        case '1':
          return styles.sticker1Hide;
        case '2':
          return styles.sticker2Hide;
        case '3':
          return styles.sticker3Hide;
        default:
          throw new Error('Unexpected value of stickerNum');
      }
    }
  }

  function handleDateTime(dateTimeString: string) {
    if (dateTimeString === undefined) return '';
    const date = dateTimeString.split(', ')[0];
    const time = dateTimeString.split(', ')[1];
    const handledHours = String((Number(time.substring(0, 2)) + 9) % 24);
    return `${date} @ ${handledHours}${time.substring(2, 5)}`;
  }
  function handleMidBarUpperContent() {
    switch (data.stickerType) {
      case 'exhibition':
        return [
          data.titleExhibition,
        ];
      case 'talk':
        return [
          data.titleTalk,
        ];
      case 'news':
        return [
          data.newsText,
        ];
      case 'event':
        return [
          data.titleEvent,
        ];
      default:
        throw new Error('Unexpected stickerType value.');
    }
  }
  function handleMidBarLowerContent() {
    switch (data.stickerType) {
      case 'exhibition':
        return [
          data.participantExhibition,
        ];
      case 'talk':
        return [
          data.participantTalk,
        ];
      case 'news':
        return ['',
        ];
      case 'event':
        return [
          data.participantEvent,
        ];
      default:
        throw new Error('Unexpected stickerType value.');
    }
  }
  function handleMidBarLowerDateTimeContent() {
    switch (data.stickerType) {
      case 'exhibition':
        return [
          handleDateTime(data.startDate),
          handleDateTime(data.endDate),
        ];
      case 'talk':
        return [
          handleDateTime(data.startDate),
        ];
      case 'news':
        return [
          handleDateTime(data.startDate),
          handleDateTime(data.endDate),
        ];
      case 'event':
        return [
          handleDateTime(data.startDate),
          handleDateTime(data.endDate),
        ];
      default:
        throw new Error('Unexpected stickerType value.');
    }
  }

  function handleColors() {
    if (data.colorSelectMode === 'random') {
      const randomInt = Math.floor(Math.random() * (50 - 0) + 0);
      switch (randomInt) {
        case 0: return [styles.redOrange1, styles.redOrange2];
        case 1: return [styles.redCyan1, styles.redCyan2];
        case 2: return [styles.redGreen1, styles.redGreen2];
        case 3: return [styles.redYellow1, styles.redYellow2];
        case 4: return [styles.orangeRed1, styles.orangeRed2];
        case 5: return [styles.orangeCyan1, styles.orangeCyan2];
        case 6: return [styles.orangeGreen1, styles.orangeGreen2];
        case 7: return [styles.orangeBlue1, styles.orangeBlue2];
        case 8: return [styles.orangeYellow1, styles.orangeYellow2];
        case 9: return [styles.orangeHanul1, styles.orangeHanul2];
        case 10: return [styles.orangeBlack1, styles.orangeBlack2];
        case 11: return [styles.cyanRed1, styles.cyanRed2];
        case 12: return [styles.cyanOrange1, styles.cyanOrange2];
        case 13: return [styles.cyanBlue1, styles.cyanBlue2];
        case 14: return [styles.cyanHanul1, styles.cyanHanul2];
        case 15: return [styles.cyanBlack1, styles.cyanBlack2];
        case 16: return [styles.greenRed1, styles.greenRed2];
        case 17: return [styles.greenOrange1, styles.greenOrange2];
        case 18: return [styles.greenBlue1, styles.greenBlue2];
        case 19: return [styles.greenHanul1, styles.greenHanul2];
        case 20: return [styles.blueOrange1, styles.blueOrange2];
        case 21: return [styles.blueCyan1, styles.blueCyan2];
        case 22: return [styles.blueGreen1, styles.blueGreen2];
        case 23: return [styles.blueYellow1, styles.blueYellow2];
        case 24: return [styles.bluePink1, styles.bluePink2];
        case 25: return [styles.blueBlack1, styles.blueBlack2];
        case 26: return [styles.yellowRed1, styles.yellowRed2];
        case 27: return [styles.yellowBlue1, styles.yellowBlue2];
        case 28: return [styles.yellowPink1, styles.yellowPink2];
        case 29: return [styles.yellowHanul1, styles.yellowHanul2];
        case 30: return [styles.yellowBlack1, styles.yellowBlack2];
        case 31: return [styles.pinkRed1, styles.pinkRed2];
        case 32: return [styles.pinkBlue1, styles.pinkBlue2];
        case 33: return [styles.pinkYellow1, styles.pinkYellow2];
        case 34: return [styles.pinkHanul1, styles.pinkHanul2];
        case 35: return [styles.pinkBlack1, styles.pinkBlack2];
        case 36: return [styles.hanulOrange1, styles.hanulOrange2];
        case 37: return [styles.hanulCyan1, styles.hanulCyan2];
        case 38: return [styles.hanulGreen1, styles.hanulGreen2];
        case 39: return [styles.hanulYellow1, styles.hanulYellow2];
        case 40: return [styles.hanulPink1, styles.hanulPink2];
        case 41: return [styles.hanulBlack1, styles.hanulBlack2];
        case 42: return [styles.blackRed1, styles.blackRed2];
        case 43: return [styles.blackOrange1, styles.blackOrange2];
        case 44: return [styles.blackCyan1, styles.blackCyan2];
        case 45: return [styles.blackGreen1, styles.blackGreen2];
        case 46: return [styles.blackBlue1, styles.blackBlue2];
        case 47: return [styles.blackYellow1, styles.blackYellow2];
        case 48: return [styles.blackPink1, styles.blackPink2];
        case 49: return [styles.blackHanul1, styles.blackHanul2];
        default:
          throw new Error(`Unexpected value of randomInt ${randomInt}`);
      }
    } else if (data.colorSelectMode === 'manual') {
      if (data.darkColor === 'red' && data.lightColor === 'orange') return [styles.redOrange1, styles.redOrange2];
      if (data.darkColor === 'red' && data.lightColor === 'cyan') return [styles.redCyan1, styles.redCyan2];
      if (data.darkColor === 'red' && data.lightColor === 'green') return [styles.redGreen1, styles.redGreen2];
      if (data.darkColor === 'red' && data.lightColor === 'yellow') return [styles.redYellow1, styles.redYellow2];
      if (data.darkColor === 'red' && data.lightColor === 'pink') return [styles.redPink1, styles.redPink2];
      if (data.darkColor === 'orange' && data.lightColor === 'red') return [styles.orangeRed1, styles.orangeRed2];
      if (data.darkColor === 'orange' && data.lightColor === 'cyan') return [styles.orangeCyan1, styles.orangeCyan2];
      if (data.darkColor === 'orange' && data.lightColor === 'green') return [styles.orangeGreen1, styles.orangeGreen2];
      if (data.darkColor === 'orange' && data.lightColor === 'blue') return [styles.orangeBlue1, styles.orangeBlue2];
      if (data.darkColor === 'orange' && data.lightColor === 'yellow') return [styles.orangeYellow1, styles.orangeYellow2];
      if (data.darkColor === 'orange' && data.lightColor === 'hanul') return [styles.orangeHanul1, styles.orangeHanul2];
      if (data.darkColor === 'orange' && data.lightColor === 'black') return [styles.orangeBlack1, styles.orangeBlack2];
      if (data.darkColor === 'cyan' && data.lightColor === 'red') return [styles.cyanRed1, styles.cyanRed2];
      if (data.darkColor === 'cyan' && data.lightColor === 'orange') return [styles.cyanOrange1, styles.cyanOrange2];
      if (data.darkColor === 'cyan' && data.lightColor === 'blue') return [styles.cyanBlue1, styles.cyanBlue2];
      if (data.darkColor === 'cyan' && data.lightColor === 'hanul') return [styles.cyanHanul1, styles.cyanHanul2];
      if (data.darkColor === 'cyan' && data.lightColor === 'black') return [styles.cyanBlack1, styles.cyanBlack2];
      if (data.darkColor === 'green' && data.lightColor === 'red') return [styles.greenRed1, styles.greenRed2];
      if (data.darkColor === 'green' && data.lightColor === 'orange') return [styles.greenOrange1, styles.greenOrange2];
      if (data.darkColor === 'green' && data.lightColor === 'blue') return [styles.greenBlue1, styles.greenBlue2];
      if (data.darkColor === 'green' && data.lightColor === 'hanul') return [styles.greenHanul1, styles.greenHanul2];
      if (data.darkColor === 'blue' && data.lightColor === 'orange') return [styles.blueOrange1, styles.blueOrange2];
      if (data.darkColor === 'blue' && data.lightColor === 'cyan') return [styles.blueCyan1, styles.blueCyan2];
      if (data.darkColor === 'blue' && data.lightColor === 'green') return [styles.blueGreen1, styles.blueGreen2];
      if (data.darkColor === 'blue' && data.lightColor === 'yellow') return [styles.blueYellow1, styles.blueYellow2];
      if (data.darkColor === 'blue' && data.lightColor === 'pink') return [styles.bluePink1, styles.bluePink2];
      if (data.darkColor === 'blue' && data.lightColor === 'black') return [styles.blueBlack1, styles.blueBlack2];
      if (data.darkColor === 'yellow' && data.lightColor === 'red') return [styles.yellowRed1, styles.yellowRed2];
      if (data.darkColor === 'yellow' && data.lightColor === 'blue') return [styles.yellowBlue1, styles.yellowBlue2];
      if (data.darkColor === 'yellow' && data.lightColor === 'pink') return [styles.yellowPink1, styles.yellowPink2];
      if (data.darkColor === 'yellow' && data.lightColor === 'hanul') return [styles.yellowHanul1, styles.yellowHanul2];
      if (data.darkColor === 'yellow' && data.lightColor === 'black') return [styles.yellowBlack1, styles.yellowBlack2];
      if (data.darkColor === 'pink' && data.lightColor === 'red') return [styles.pinkRed1, styles.pinkRed2];
      if (data.darkColor === 'pink' && data.lightColor === 'blue') return [styles.pinkBlue1, styles.pinkBlue2];
      if (data.darkColor === 'pink' && data.lightColor === 'yellow') return [styles.pinkYellow1, styles.pinkYellow2];
      if (data.darkColor === 'pink' && data.lightColor === 'hanul') return [styles.pinkHanul1, styles.pinkHanul2];
      if (data.darkColor === 'pink' && data.lightColor === 'black') return [styles.pinkBlack1, styles.pinkBlack2];
      if (data.darkColor === 'hanul' && data.lightColor === 'orange') return [styles.hanulOrange1, styles.hanulOrange2];
      if (data.darkColor === 'hanul' && data.lightColor === 'cyan') return [styles.hanulCyan1, styles.hanulCyan2];
      if (data.darkColor === 'hanul' && data.lightColor === 'green') return [styles.hanulGreen1, styles.hanulGreen2];
      if (data.darkColor === 'hanul' && data.lightColor === 'yellow') return [styles.hanulYellow1, styles.hanulYellow2];
      if (data.darkColor === 'hanul' && data.lightColor === 'pink') return [styles.hanulPink1, styles.hanulPink2];
      if (data.darkColor === 'hanul' && data.lightColor === 'black') return [styles.hanulBlack1, styles.hanulBlack2];
      if (data.darkColor === 'black' && data.lightColor === 'red') return [styles.blackRed1, styles.blackRed2];
      if (data.darkColor === 'black' && data.lightColor === 'orange') return [styles.blackOrange1, styles.blackOrange2];
      if (data.darkColor === 'black' && data.lightColor === 'cyan') return [styles.blackCyan1, styles.blackCyan2];
      if (data.darkColor === 'black' && data.lightColor === 'green') return [styles.blackGreen1, styles.blackGreen2];
      if (data.darkColor === 'black' && data.lightColor === 'blue') return [styles.blackBlue1, styles.blackBlue2];
      if (data.darkColor === 'black' && data.lightColor === 'yellow') return [styles.blackYellow1, styles.blackYellow2];
      if (data.darkColor === 'black' && data.lightColor === 'pink') return [styles.blackPink1, styles.blackPink2];
      if (data.darkColor === 'black' && data.lightColor === 'hanul') return [styles.blackHanul1, styles.blackHanul2];
    }
    return true;
  }
  const thisStickersColors: any = handleColors();

  const randomDegree = Math.floor(Math.random() * (65 - 0) + 0);
  const randomSign = Math.round(Math.random());
  const randomRotation = randomSign ? randomDegree : randomDegree * -1;

  const noLinkContent = (
    <>
      <StickerTopBar
        location="general"
        design="general"
        colorStyle={thisStickersColors[0]}
        topLineText={data.topLineExhibition
        || data.topLineTalk
        || data.topLineEvent
        || data.topLineNews}
      />
      <div className={`${styles.midBarAlignLeft} ${thisStickersColors[0]}`}>
        {handleMidBarUpperContent().map(
          (content: any) => <p key="title0" className={styles.stickerTitle}>{content.toUpperCase()}</p>,
        )}
      </div>
      <div className={`${styles.midBarAlignRight} ${thisStickersColors[1]}`}>
        {handleMidBarLowerContent().map(
          (content) => (content ? (<p key="people0" className={styles.stickerPeople}>{content.toUpperCase()}</p>) : null),
        )}
        {handleMidBarLowerDateTimeContent().map(
          (content: any, i: number) => <p key={i === 0 ? '0' : '1'} className={styles.stickerDateTime}>{content}</p>,
        )}
      </div>
    </>
  );
  const linkContent = (
    data.internalExternal === 'internal'
      ? (
        <Link to={`/${data.url}`}>
          {noLinkContent}
        </Link>
      )
      : (
        <a href={data.url}>
          {noLinkContent}
        </a>
      )
  );

  function getRandomPct(min: number, max: number): string {
    const randomInt = Math.floor(Math.random() * ((max + 1) - min) + min);
    return `${randomInt}%`;
  }

  useLayoutEffect(() => {
    stickerRef.current.style.transition = 'top 0.5s ease-out, left 0.5s ease-out';
    stickerRef.current.style.top = getRandomPct(topRange[0], topRange[1]);
    stickerRef.current.style.left = getRandomPct(leftRange[0], leftRange[1]);
    stickerRef.current.style.transform = `rotate(${randomRotation}deg)`;
    if (window.outerWidth >= 700 && data.titleInternal === 'Sticker 1') {
      stickerRef.current.style.display = 'flex';
    } else if (window.outerWidth < 700 && data.titleInternal === 'Sticker 1') {
      stickerRef.current.style.display = 'none';
    }
    if (window.outerWidth >= 1024 && data.titleInternal === 'Sticker 2') {
      stickerRef.current.style.display = 'flex';
    } else if (window.outerWidth < 1024 && data.titleInternal === 'Sticker 2') {
      stickerRef.current.style.display = 'none';
    }
    if (window.outerWidth >= 1200 && data.titleInternal === 'Sticker 3') {
      stickerRef.current.style.display = 'flex';
    } else if (window.outerWidth < 1200 && data.titleInternal === 'Sticker 3') {
      stickerRef.current.style.display = 'none';
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (hideSticker) {
      stickerRef.current.style.display = 'none';
    } else {
      if (window.outerWidth >= 700 && data.titleInternal === 'Sticker 1') {
        stickerRef.current.style.display = 'flex';
      } else if (window.outerWidth < 700 && data.titleInternal === 'Sticker 1') {
        stickerRef.current.style.display = 'none';
      }
      if (window.outerWidth >= 1024 && data.titleInternal === 'Sticker 2') {
        stickerRef.current.style.display = 'flex';
      } else if (window.outerWidth < 1024 && data.titleInternal === 'Sticker 2') {
        stickerRef.current.style.display = 'none';
      }
      if (window.outerWidth >= 1200 && data.titleInternal === 'Sticker 3') {
        stickerRef.current.style.display = 'flex';
      } else if (window.outerWidth < 1200 && data.titleInternal === 'Sticker 3') {
        stickerRef.current.style.display = 'none';
      }
    }
  }, [hideSticker]);

  return (
    <div className={`${getStickerClass()}`} ref={stickerRef}>
      {data.url ? linkContent : noLinkContent}
      <StickerCloseBar
        location="general"
        colorStyle={thisStickersColors[1]}
        closeButtonText={data.closeButtonText}
        hideSticker={hideSticker}
        setHideSticker={setHideSticker}
      />
    </div>
  );
}
