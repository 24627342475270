import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import StickerSpace from './StickerSpace';
import StickerGeneral from './StickerGeneral';

export default function StickerLayer() {
  const { allSanitySticker } = useStaticQuery(
    graphql`
      query {
        allSanitySticker {
          nodes {
            titleInternal
            titleTalk
            topLineSpace
            topLineEvent
            topLineExhibition
            topLineTalk
            topLineNews
            titleExhibition
            titleEvent
            textSpace {
              children {
                text
              }
            }
            stickerType
            startDate (formatString: "YYYY년 MM월 DD일, kk:mm")
            participantTalk
            participantExhibition
            participantEvent
            newsText
            lightColor
            _id
            closeButtonText
            colorSelectMode
            darkColor
            endDate (formatString: "YYYY년 MM월 DD일, kk:mm")
            url
            internalExternal
          }
        }
      }`,
  );
  const stickerList = allSanitySticker.nodes;
  const stickerSpace = stickerList.find((sticker: any) => sticker.titleInternal === 'Space Sticker');
  const sticker1 = stickerList.find((sticker: any) => sticker.titleInternal === 'Sticker 1');
  const sticker2 = stickerList.find((sticker: any) => sticker.titleInternal === 'Sticker 2');
  const sticker3 = stickerList.find((sticker: any) => sticker.titleInternal === 'Sticker 3');

  return (
    <>
      {/* topRange and leftRange format is [min, max] */}
      <StickerSpace topRange={[5, 20]} leftRange={[5, 20]} data={stickerSpace} />
      {sticker1
        ? <StickerGeneral topRange={[5, 30]} leftRange={[40, 60]} data={sticker1} />
        : null}
      {sticker2
        ? <StickerGeneral topRange={[40, 80]} leftRange={[5, 40]} data={sticker2} />
        : null}
      {sticker3
        ? <StickerGeneral topRange={[55, 75]} leftRange={[55, 75]} data={sticker3} />
        : null}
    </>
  );
}
