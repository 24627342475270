import * as React from 'react';
import { useState } from 'react';
import type { HeadFC } from 'gatsby';
import { graphql, Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import '../scss/main.scss';
import * as styles from '../scss/components/contentGrid.module.scss';
import Header from '../components/Header';
import MenuModal from '../components/MenuModal';
import StickerLayer from '../components/stickers/StickerLayer';
import Footer from '../components/Footer';

function IndexPage({ data }:any) {
  function getContentCategoryClassName(contentCat: string) {
    switch (contentCat) {
      case 'exhibition':
        return styles.iconExhibition;
      case 'talk':
        return styles.iconTalk;
      case 'event':
        return styles.iconEvent;
      case 'magazine':
        return styles.iconMagazine;
      case 'book':
        return styles.iconBook;
      case 'news':
        return styles.iconNews;
      default:
        throw new Error('big deal!');
    }
  }

  function handleItemTitleStyle(contentCategory: string) {
    switch (contentCategory) {
      case 'exhibition':
        return styles.itemTitleExhibition;
      case 'talk':
        return styles.itemTitleTalk;
      case 'event':
        return styles.itemTitleEvent;
      case 'magazine':
        return styles.itemTitleMagazine;
      case 'book':
        return styles.itemTitleBook;
      case 'news':
        return styles.itemTitleNews;
      default:
        throw new Error('대박사건! Unexpected value for contentCategory');
    }
  }

  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <>
      <Header menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
      {menuOpen ? null : (
        <main className={styles.main}>
          <div className={styles.contentGridHome}>
            {data.allSanityContent.nodes.sort((a: any, b: any) => {
              const dateA = a.programDate || a.openDate || a.publicationDate ? a.programDate || a.openDate || a.publicationDate : '0';
              const dateB = b.programDate || b.openDate || b.publicationDate ? b.programDate || b.openDate || b.publicationDate : '0';
              if (dateA > dateB) {
                return -1;
              } if (dateA < dateB) {
                return 1;
              } return 0;
            }).map((node: any) => (
              <Link key={node.id} className={styles.iconLink} to={`/${node.slug.current}`}>
                <GatsbyImage
                  className={getContentCategoryClassName(node.contentCategory)}
                  image={node.mainImage.asset.gatsbyImageData}
                  alt={node.title}
                />
                <div className={handleItemTitleStyle(node.contentCategory)}>{node.title}</div>
              </Link>
            ))}
          </div>
          <StickerLayer />
        </main>
      )}
      {menuOpen ? null : <Footer />}
      <MenuModal menuOpen={menuOpen} />
    </>
  );
}

export const query = graphql`
query {
  allSanityContent (sort: {fields: _createdAt, order: DESC}) {
    nodes {
      id
      title
      contentCategory
      slug {
        current
      }
      mainImage {
        asset {
          gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
        }
      }
      programDate(formatString: "YYYY년 MM월 DD일, kk:mm")
      openDate(formatString: "YYYY년 MM월 DD일, kk:mm")
      publicationDate(formatString: "YYYY년 MM월 DD일, kk:mm")
    }
  }
}`;

export default IndexPage;

// eslint-disable-next-line react/function-component-definition
export const Head: HeadFC = () => <title>Domansa</title>;
