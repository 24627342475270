import React from 'react';
import * as styles from '../../scss/components/stickers/stickers.module.scss';

export default function StickerTopBar(props: {
  location: string,
  design: string,
  colorStyle: string[],
  topLineText: string
}) {
  const {
    location,
    design,
    colorStyle,
    topLineText,
  } = props;

  const topLineString = location === 'space' ? [topLineText.split(' ').map((word: any) => word)[0]] : topLineText.split(' ').map((word: any) => word);
  if (location !== 'space') topLineString.splice(1, 0, '☆');
  topLineString.unshift('☆');
  topLineString.push('☆');

  function handleTopBarClassName() {
    switch (design) {
      case '1':
        return styles.topBar;
      case '2':
        return styles.topBarDesign2;
      case '3':
        return styles.topBar;
      case '4':
        return styles.topBar;
      default:
        return styles.topBar;
    }
  }

  function handleSpaceTextClassName(index: number) {
    if (design === '1') return styles.topBarPDesign1;
    if (design === '2' || design === '3' || design === '4') {
      switch (index) {
        case 0:
          return styles.topBarStarSpace;
        case 1:
          return styles.topBarPSpace;
        case 2:
          return styles.topBarStarSpace;
        default:
          return styles.topBarStarSpace;
      }
    }
    return styles.topBarP;
  }

  const text = design === '1'
    ? [{
      key: 0,
      className: handleSpaceTextClassName(0),
      text: '도만사',
    }]
    : topLineString.map(
      (word: any, i: number) => (
        {
          key: i,
          className: handleSpaceTextClassName(i),
          text: word,
        }
      ),
    );

  return (
    <div className={`${handleTopBarClassName()} ${colorStyle}`}>
      {
        text.map((textObject) => (
          <p key={textObject.key} className={textObject.className}>
            {textObject.text}
          </p>
        ))
      }
    </div>
  );
}
