import React from 'react';
import * as styles from '../../scss/components/stickers/stickers.module.scss';

export default function StickerCloseBar(props: {
  location: string,
  closeButtonText: string,
  colorStyle: any,
  hideSticker: any,
  setHideSticker: any,
}) {
  const {
    location,
    closeButtonText,
    colorStyle,
    hideSticker,
    setHideSticker,
  } = props;

  function handleClick() {
    if (!hideSticker) {
      setHideSticker(true);
    } else {
      setHideSticker(false);
    }
  }

  return (
    <div
      className={`${styles.closeBar} ${colorStyle}`}
      onClick={handleClick}
    >
      <p className={location === 'general' ? styles.closeBarP : styles.closeBarPSpace}>☆</p>
      <p className={location === 'general' ? styles.closeBarP : styles.closeBarPSpace}>{`${closeButtonText}`}</p>
      <p className={location === 'general' ? styles.closeBarP : styles.closeBarPSpace}>☆</p>
    </div>
  );
}
